var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "com_template_unit" }, [
    1
      ? _c(
          "div",
          {
            staticClass: "new_content_group",
            class: {
              isimportant: _vm.unitdata.isimportant && 0,
              isdo: _vm.unitdata.isdo && !_vm.unitdata.isimportant && 0,
              both: _vm.unitdata.isimportant && _vm.unitdata.isdo && 0,
            },
            on: { click: _vm.openTaskBox },
          },
          [
            _vm.unitdata.colour
              ? _c(
                  "div",
                  { staticClass: "color_group" },
                  [
                    _vm._l(
                      _vm.getColorList(_vm.unitdata),
                      function (item, index) {
                        return [
                          _c("div", {
                            key: index,
                            staticClass: "color",
                            style: { "border-left-color": item },
                          }),
                        ]
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.unitdata.dot,
                  expression: "unitdata.dot",
                },
              ],
              staticClass: "dot",
            }),
            0
              ? _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "bottom",
                      title: "标题",
                      width: "200",
                      trigger: "click",
                      content:
                        "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "unit_fun_btn cp",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "info_group" }, [
              _c(
                "div",
                { staticClass: "info_box" },
                [
                  [
                    _c("div", { staticClass: "info cp un_sel" }, [
                      _vm._v(
                        _vm._s(
                          _vm.unitdata.taskDetail ||
                            _vm.unitdata.name ||
                            _vm.unitdata.taskName
                        )
                      ),
                    ]),
                  ],
                  _vm.more_btn
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right-start",
                            trigger: "click",
                            "popper-class": "com_template_unit_more_btn",
                            "visible-arrow": false,
                          },
                          model: {
                            value: _vm.switch_more_btn,
                            callback: function ($$v) {
                              _vm.switch_more_btn = $$v
                            },
                            expression: "switch_more_btn",
                          },
                        },
                        [
                          _c("div", { staticClass: "more_list" }, [
                            _c(
                              "div",
                              {
                                staticClass: "list_group un_sel cp",
                                on: {
                                  click: function ($event) {
                                    _vm.switch_more_btn = false
                                    _vm.openTagBox()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: require("@/assets/images/fun_template/more_menu_tag.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("设置标签"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "list_group un_sel cp",
                                on: {
                                  click: function ($event) {
                                    _vm.switch_more_btn = false
                                    _vm.doShare()
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "icon",
                                  attrs: {
                                    src: require("@/assets/images/task/task_share.png"),
                                    alt: "",
                                  },
                                }),
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("分享任务"),
                                ]),
                              ]
                            ),
                            0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "list_group un_sel cp",
                                    on: {
                                      click: function ($event) {
                                        _vm.switch_more_btn = false
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "icon",
                                      attrs: {
                                        src: require("@/assets/images/fun_template/more_menu_transmit.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v("转发任务"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", {
                            staticClass: "more_btn cp",
                            attrs: { slot: "reference" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openMoreMenu()
                              },
                            },
                            slot: "reference",
                          }),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "time_box" }, [
                _vm._v(
                  _vm._s(_vm._f("universalDate")(_vm.unitdata.updateTime))
                ),
              ]),
            ]),
            _c("div", { staticClass: "template_info_group" }, [
              _vm.unitdata.taskType == 2 && _vm.unitdata.draft !== 1
                ? _c("div")
                : _vm._e(),
              (_vm.unitdata.taskType != 2 || _vm.unitdata.draft === 1) &&
              _vm.unitdata.createuserthumbnail
                ? _c(
                    "div",
                    { staticClass: "avatar_group" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: _vm.unitdata.createusername,
                            placement: "bottom",
                            "open-delay": 500,
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "avatar",
                            attrs: {
                              src: `${_vm.$avatar_url}${_vm.unitdata.createuserthumbnail}@!small200`,
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.unitdata.taskchatnum && 0,
                            expression: "unitdata.taskchatnum && 0",
                          },
                        ],
                        staticClass: "new_chat_icon",
                        attrs: {
                          src: require("@/assets/images/dialog/new_chat_icon.gif"),
                          alt: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.is_sell
                ? _c("div", { staticClass: "tag_box" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.unitdata.taskType == 2 ||
                              _vm.unitdata.taskType == 3,
                            expression:
                              "unitdata.taskType == 2 || unitdata.taskType == 3",
                          },
                        ],
                        staticClass: "tag teach",
                      },
                      [_vm._v("教")]
                    ),
                  ])
                : _vm._e(),
              _vm.is_sell
                ? _c(
                    "div",
                    {
                      staticClass: "check_status",
                      class: [
                        _vm.transformCheckStaus(_vm.unitdata.checkstatus)
                          .class_name,
                      ],
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.transformCheckStaus(_vm.unitdata.checkstatus)
                              .text
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.show_from
      ? _c(
          "div",
          { staticClass: "from_group cp", on: { click: _vm.openTaskBox } },
          [
            _c("div", { staticClass: "tag" }, [_vm._v("来源:")]),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: _vm.unitdata.from,
                  placement: "bottom-start",
                  "open-delay": 500,
                },
              },
              [
                _c("div", { staticClass: "from" }, [
                  _vm._v(_vm._s(_vm.unitdata.from)),
                ]),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    0
      ? _c(
          "div",
          { staticClass: "content_group cp", on: { click: _vm.openTaskBox } },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.unitdata.taskType == 2 || _vm.unitdata.taskType == 3,
                    expression:
                      "unitdata.taskType == 2 || unitdata.taskType == 3",
                  },
                ],
                staticClass: "tag_group",
              },
              [_c("div", { staticClass: "tag" }, [_vm._v("教")])]
            ),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.unitdata.name || _vm.unitdata.taskName)),
            ]),
          ]
        )
      : _vm._e(),
    0
      ? _c("div", { staticClass: "info_group" }, [
          _c("div", { staticClass: "tag cp", on: { click: _vm.openTagBox } }),
          !_vm.is_sell
            ? _c("div", { staticClass: "time" }, [
                _vm._v(
                  _vm._s(_vm._f("universalDate")(_vm.unitdata.updateTime))
                ),
              ])
            : _vm._e(),
          _vm.is_sell
            ? _c(
                "div",
                {
                  staticClass: "check_status",
                  class: [
                    _vm.transformCheckStaus(_vm.unitdata.checkstatus)
                      .class_name,
                  ],
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.transformCheckStaus(_vm.unitdata.checkstatus).text
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }