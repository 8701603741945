import { render, staticRenderFns } from "./course_package_list.vue?vue&type=template&id=b5fda540&scoped=true"
import script from "./course_package_list.vue?vue&type=script&lang=js"
export * from "./course_package_list.vue?vue&type=script&lang=js"
import style0 from "./course_package_list.vue?vue&type=style&index=0&id=b5fda540&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5fda540",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/workspace/pmcs-web-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b5fda540')) {
      api.createRecord('b5fda540', component.options)
    } else {
      api.reload('b5fda540', component.options)
    }
    module.hot.accept("./course_package_list.vue?vue&type=template&id=b5fda540&scoped=true", function () {
      api.rerender('b5fda540', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/fun_template/course_package_list.vue"
export default component.exports